<template>
  <div>
    <div class="main-header">
      <div class="title">数据字典</div>
    </div>
    <div class="main-main" style="display: flex">
      <a-card class="side-tree">
        <div
          v-for="item in allDict"
          :key="item.id"
          :class="{ dict: true, selected: selectedDict == item.id }"
          @click="selectDict(item.id)"
        >
          {{ item.title }}
        </div>
      </a-card>
      <a-card style="flex: 1">
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addOption">新增</a-button>
          </div>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="editOption(row)">编辑</a>
                <a @click="delOption(row)">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal :title="modalTitle" v-model="addOrEditModal" :footer="null">
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 17 }"
      >
        <a-form-model-item prop="name" label="选项名称">
          <a-input v-model="form.name"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: center">
        <a-button @click="saveOption" :disabled="btnDisable" type="primary"
          >保存提交</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getOptions, delOption, saveOption } from "@/network/api";
import Pagination from "../components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      selectedDict: "",
      btnDisable: false,
      form: {},
      addOrEditModal: false,
      modalTitle: "",
      tableLoading: false,
      tableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  methods: {
    selectDict(v) {
      this.selectedDict = v;
      this.getOptions();
    },

    async getOptions(
      params = {
        categoryId: this.selectedDict,
        typeId: 0,
      }
    ) {
      this.tableLoading = true;
      const { data } = await getOptions(params);
      this.tableData = data.content;
      this.tableLoading = false;
    },
    saveOption() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.btnDisable = true;
          saveOption({ ...this.form, display: 1,categoryId: this.selectedDict }).then((res) => {
            if (res.status == 0) {
              this.$message.success("成功！");
              this.addOrEditModal = false;
              this.getOptions();
            } else {
              this.btnDisable = false;
            }
          });
        }
      });
    },
    addOption() {
      this.modalTitle = "新增选项";
      this.form = {
        name: "",
      };
      this.btnDisable = false;
      this.addOrEditModal = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    editOption(row) {
      console.log(row);
      this.modalTitle = "编辑选项";
      this.form = {
        categoryId: row.categoryId,
        name: row.name,
        id: row.id,
        parentId: 0,
      };
      this.btnDisable = false;
      this.addOrEditModal = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    delOption(row) {
      this.$confirm({
        title: "确认删除该选项吗？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            delOption({ id: row.id }).then((res) => {
              if (res.status == 0) {
                this.$message.success("删除成功！");
                this.getOptions();
                resolve();
              } else {
                this.$message.error(res.msg);
              }
            });
          });
        },
      });
    },

    // pageChange(page, pageSize) {
    //   this.searchOptions.page = page;
    //   this.getOptions(this.searchOptions);
    // },
    // pageSizeChange(page, pageSize) {
    //   this.searchOptions.page = 1;
    //   this.searchOptions.pageSize = pageSize;
    //   this.getOptions(this.searchOptions);
    // },
    // refresh() {
    //   this.getOptions();
    // },
  },

  created() {
    this.selectDict(this.allDict[0].id);
  },
  computed: {
    allDict() {
      return [
        {
          id: 27,
          title: "活动类型",
        },
        {
          id: 28,
          title: "商品类型",
        },
      ];
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: "请输入选项名称！",
            trigger: "blur",
          },
        ],
      };
    },
    tableColumns() {
      return [
        {
          dataIndex: "name",
          title: "选项名称",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.admin-list {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.admin-info {
  flex: 1;
  text-align: center;
}
.side-tree {
  // width: 200px;
  margin-right: 15px;
  .dict {
    min-width: 100px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
    transition: background 0.5s;
  }
  .dict.selected {
    background: #bae7ff;
  }
}
</style>